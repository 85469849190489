import { FrontendExceptionData } from '@/types/commandBlock';
import { APIExceptionData } from '@diamond-block/errors';

export class APIException extends Error {
  constructor(
    public readonly data: APIExceptionData[] | FrontendExceptionData[],
  ) {
    super('API returned an error');
  }
}

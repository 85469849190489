'use client';

import { FeatureFlags } from '@/helpers/props';
import { createContext, useContext } from 'react';

export const FeatureFlagsContext = createContext<FeatureFlags>(
  {} as FeatureFlags,
);

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const FeatureFlagsProvider = (
  props: Parameters<typeof FeatureFlagsContext.Provider>[0],
) => <FeatureFlagsContext.Provider {...props} />;

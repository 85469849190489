'use client';

import { agreeToCookies } from '@/app/lib/consent';
import { useTranslations } from 'next-intl';
import { TbCheck, TbInfoCircle } from 'react-icons/tb';

export const CookieBanner = ({
  cookiesAccepted,
}: {
  cookiesAccepted: boolean;
}) => {
  const t = useTranslations('CookieBanner');

  return (
    !cookiesAccepted && (
      <div className={`alert fixed bottom-4 right-4 z-50 mx-auto max-w-lg`}>
        <TbInfoCircle size={24} className="min-h-8" />
        <span>{t('we-use-cookies')}</span>
        <button
          className="btn btn-ghost btn-sm"
          onClick={() => agreeToCookies()}
        >
          <TbCheck />
          {t('ok')}
        </button>
      </div>
    )
  );
};

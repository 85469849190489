'use client';

import { DCSession } from '@/types/auth';
import { signOut, useSession } from 'next-auth/react';
import { useEffect } from 'react';

export const SessionLogoutHandler = () => {
  const { data: session } = useSession();

  useEffect(() => {
    if (!session) return;

    if ((session as unknown as DCSession).error === 'RefreshAccessTokenError') {
      signOut();
    }
  }, [session]);
  return null;
};

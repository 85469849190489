'use client';

import { signIn, signOut, useSession } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { TbLogin, TbLogout, TbUser } from 'react-icons/tb';

export const AccountMenu = () => {
  const t = useTranslations('AccountMenu');
  const { status, data: session } = useSession();

  return (
    <>
      {status === 'authenticated' && (
        <div className="dropdown dropdown-end dropdown-hover">
          <div tabIndex={0} role="button" className="btn btn-ghost rounded-btn">
            <TbUser size={20} aria-hidden /> {session.user?.name}
          </div>
          <ul
            tabIndex={0}
            className="menu dropdown-content z-[2] min-w-48 rounded-box bg-base-100 p-2 text-base-content shadow dark:bg-base-200 dark:shadow-telekom-gray"
          >
            <li>
              <a onClick={() => signOut()}>
                <TbLogout size={18} aria-hidden /> {t('logout')}
              </a>
            </li>
          </ul>
        </div>
      )}
      {status === 'unauthenticated' && (
        <button className="btn btn-ghost" onClick={() => signIn('keycloak')}>
          <TbLogin size={20} aria-hidden /> {t('login')}
        </button>
      )}
      {status === 'loading' && (
        <div className="btn btn-ghost">
          <span className="loading loading-spinner loading-xs"></span>
          {t('loading')}
        </div>
      )}
    </>
  );
};

'use client';

import { FrontendExceptionData } from '@/types/commandBlock';
import { APIExceptionData } from '@diamond-block/errors';
import { useTranslations } from 'next-intl';

export const ErrorDisplay = ({
  error,
}: {
  error: APIExceptionData | FrontendExceptionData;
}) => {
  const t = useTranslations('_APIErrors');

  // @ts-expect-error this is hard to type properly
  return t(error.type, {
    subject: error.subject,
    constraint: error.constraint,
  });
};
